:root {
  --textarea-border: #ced4da;
  --textarea-background: #f3f3f3;
  --border-radius: 8px;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  text-align: center;
  background-color: white;
  color: black;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}

body,
button,
textarea,
input {
  font: 22px/1.5 Segoe UI, Roboto;
}

.header {
  display: flex;
  flex-direction: row;

  justify-content: center;

  width: 100%;
}

.header .header-group {
  display: flex;
  flex-direction: row;
}

.header-group.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header-group.right {
  position: absolute;
  right: 0;
}

.control {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;

  width: 80px;
  height: 80px;

  transition: transform 0.2s ease;

  cursor: pointer;
  user-select: none;
}

.control:hover {
  transform: scale(1.1);
}

.control[data-disabled="true"] {
  filter: brightness(80%);
  pointer-events: none;
}

.control img {
  display: block;
  width: 100%;
  height: 100%;

  margin-left: auto;
  margin-right: auto;
}

.game {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}

.game p {
  margin: 0;
  padding: 0;
}

.progress {
  width: 100vw;

  position: absolute;
  bottom: 0;
  left: 0;

  overflow: hidden;

  /*
    JavaScript:
      height: 15px;
  */
}

.init {
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: 400px;
  height: 600px;

  justify-content: center;
}

.init textarea {
  height: 100%;
}

textarea,
input[type="text"] {
  line-height: 1.5;
  resize: none;
  outline: none;

  padding: 0.375rem 0.75rem;

  border: 1px solid var(--textarea-border);
  border-radius: var(--border-radius);

  background: var(--textarea-background);
}

button,
input[type="submit"] {
  border-width: 0;
  border-radius: var(--border-radius);
  font-weight: 600;
}

.hidden {
  display: none;
}

.fade-out {
  transition: opacity 250ms;
  opacity: 0;
}
