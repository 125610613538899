:root {
  --textarea-border: #ced4da;
  --textarea-background: #f3f3f3;
  --border-radius: 8px;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  text-align: center;
  color: #000;
  background-color: #fff;
}

h1, h2, h3 {
  margin: 0;
  padding: 0;
}

body, button, textarea, input {
  font: 22px / 1.5 Segoe UI, Roboto;
}

.header {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.header .header-group {
  flex-direction: row;
  display: flex;
}

.header-group.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header-group.right {
  position: absolute;
  right: 0;
}

.control {
  width: 80px;
  height: 80px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin: 20px;
  transition: transform .2s;
}

.control:hover {
  transform: scale(1.1);
}

.control[data-disabled="true"] {
  filter: brightness(80%);
  pointer-events: none;
}

.control img {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.game {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.game p {
  margin: 0;
  padding: 0;
}

.progress {
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.init {
  width: 400px;
  height: 600px;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  display: flex;
}

.init textarea {
  height: 100%;
}

textarea, input[type="text"] {
  resize: none;
  border: 1px solid var(--textarea-border);
  border-radius: var(--border-radius);
  background: var(--textarea-background);
  outline: none;
  padding: .375rem .75rem;
  line-height: 1.5;
}

button, input[type="submit"] {
  border-radius: var(--border-radius);
  border-width: 0;
  font-weight: 600;
}

.hidden {
  display: none;
}

.fade-out {
  opacity: 0;
  transition: opacity .25s;
}

/*# sourceMappingURL=index.0d55f7b3.css.map */
